// Global spinner control
window.showLoader = () => {
    let loadingContainer = document.getElementById('loading-container')

    loadingContainer.style.visibility = 'visible'
    fadeIn(loadingContainer, 'flex')
}

window.hideLoader = () => {
    let loadingContainer = document.getElementById('loading-container')

    fadeOut(loadingContainer)
}

window.scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}


// Notifications
window.createNotification = (message, notificationType) => {
    let icon, colorClass

    if (notificationType == 'error') {
        icon = '<svg xmlns="http://www.w3.org/2000/svg" class="fill-current h-10" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75"><path d="M 38,19C 48.4934,19 57,27.5066 57,38C 57,48.4934 48.4934,57 38,57C 27.5066,57 19,48.4934 19,38C 19,27.5066 27.5066,19 38,19 Z M 34.0417,25.7292L 36.0208,41.9584L 39.9792,41.9583L 41.9583,25.7292L 34.0417,25.7292 Z M 38,44.3333C 36.2511,44.3333 34.8333,45.7511 34.8333,47.5C 34.8333,49.2489 36.2511,50.6667 38,50.6667C 39.7489,50.6667 41.1667,49.2489 41.1667,47.5C 41.1667,45.7511 39.7489,44.3333 38,44.3333 Z" ></path></svg>'
        colorClass = 'bg-alert-red'

    } else if (notificationType == 'ok') {
        icon = '<svg xmlns="http://www.w3.org/2000/svg" class="fill-current h-7" viewBox="0 0 40 40"><polygon points="36.44 13.08 32.72 9.36 17.17 24.91 8.88 16.62 5.17 20.34 17.17 32.34 17.17 32.34 17.17 32.34 36.44 13.08" /></svg>'
        colorClass = 'bg-alert-green'

    } else if (notificationType == 'warning') {
        icon = '<svg xmlns="http://www.w3.org/2000/svg" class="fill-current h-11" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-6 -8 90 90"><path d="M 58.5832,55.4172L 17.4169,55.4171C 15.5619,53.5621 15.5619,50.5546 17.4168,48.6996L 35.201,15.8402C 37.056,13.9852 40.0635,13.9852 41.9185,15.8402L 58.5832,48.6997C 60.4382,50.5546 60.4382,53.5622 58.5832,55.4172 Z M 34.0417,25.7292L 36.0208,41.9584L 39.9791,41.9583L 41.9583,25.7292L 34.0417,25.7292 Z M 38,44.3333C 36.2511,44.3333 34.8333,45.7511 34.8333,47.5C 34.8333,49.2489 36.2511,50.6667 38,50.6667C 39.7489,50.6667 41.1666,49.2489 41.1666,47.5C 41.1666,45.7511 39.7489,44.3333 38,44.3333 Z" /></svg>'
        colorClass = 'bg-alert-orange'

    } else {
        icon = ''
        colorClass = 'bg-white'
    }

    document.getElementsByClassName('notifications')[0].innerHTML = `
        <div class="flex items-center text-white ${ colorClass } min-h-[2.75rem] mb-1 rounded">
            <div class="h-12 w-12 flex items-center justify-center shrink-0">${ icon }</div>
            <span class="[&>a]:underline py-2.5">${ message }</span>
        </div>
    `
}

window.clearNotifications = () => {
    document.getElementsByClassName('notifications')[0].innerHTML = ''
}


/**
 * Nolasa datumu no norādītā personas koda pirmās puses.
 *
 * @param {String} personCode
 * 
 * @return Date|null
 */
window.getBirthDateFromPersonCode = personCode => {
    personCode = personCode.trim().replace('-', '')

    if (personCode.length >= 7) {
        const date = parseInt(personCode[0] + personCode[1])

        // Automatically update birth date if PersonCode gives a valid one
        if (date > 0 && date <= 31) {
            const twoLastYearDigits = personCode[4] + personCode[5]
            const month = parseInt(personCode[2] + personCode[3])
            const year = parseInt(personCode[6] == 1 ? ('19' + twoLastYearDigits) : ('20' + twoLastYearDigits))

            if (month > 0 && month <= 12 && twoLastYearDigits > 0) {
                return new Date(year, month - 1, date)
            }
        }
    }

    return null
}


function fadeOut(el) {
    el.style.opacity = 1;

    (function fade() {
        if ((el.style.opacity -= .1) <= 0) {
            el.style.display = "none"
        } else {
            requestAnimationFrame(fade)
        }
    })()
}

function fadeIn(el, display) {
    el.style.opacity = 0
    el.style.display = display || "block";

    (function fade() {
        let val = parseFloat(el.style.opacity)

        if (!((val += .1) >= 1)) {
            el.style.opacity = val
            requestAnimationFrame(fade)
        }
    })()
}


/**
 * @param {Number} accepted - 1/0
 */
window.saveCookieConsent = (accepted) => {
    document.getElementById('cookie-consent-popup').remove()

    let cookieExpirationDate = new Date

    cookieExpirationDate.setFullYear(cookieExpirationDate.getFullYear() + 1)
    document.cookie = 'cookie_consent=' + accepted + '; expires=' + cookieExpirationDate.toUTCString() + '; path=/'
}


/**
 * https://laravel.com/docs/11.x/vite#blade-processing-static-assets
 */
import.meta.glob([
    '../../images/**',
])
